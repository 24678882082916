div.modalContainer {
  display: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #00000000;
  z-index: 3;
}

div.modalContainer.visible {
  display: block;
}

div.modalShade {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #00000090;
}

div.modalBox {
  position: relative;
  left: 50%;
  top: 50%;
  max-width: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-colour);
  padding: 15px;
}

span.modalHeader {
  position: relative;
  color: var(--text-colour);
  width: 100%;
  display: inline-block;
  font-size: 25px;
  font-weight: bold;
  vertical-align: middle;
}

span.modalHeader p {
  display: inline-block;
  margin: 0px;
}

button.closeButton {
  float: right;
  position: relative;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0px;
  cursor: pointer;
}

button.closeButton:active {
  border: none;
  outline: none;
}

button.closeButton > span {
  position: absolute;
  display: inline-block;
  background-color: var(--highlight-colour);
  transition: background-color 0.2s, border-color 0.2s;
  border: 0px solid;
  border-radius: 5px;
}

button.closeButton:hover > span {
  background-color: var(--negative-accent-colour);
}

button.closeButton > span:nth-child(1) {
  width: 25px;
  height: 5px;
  left: 2px;
  top: 12px;
  transform: rotate(45deg);
}

button.closeButton > span:nth-child(2) {
  width: 25px;
  height: 5px;
  left: 2px;
  top: 12px;
  transform: rotate(-45deg);
}
