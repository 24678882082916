span.controlBar {
  display: inline-block;
  min-width: 50px;
  height: 100vh;
  background-color: var(--lowlight-colour);
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
}

div.controlBarTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-flow: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  position: absolute;
  top: 0;
}

div.controlBarBottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}

span.detailContainer {
  position: relative;
  display: inline-block;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 100vh;
  transition: width 0.5s;
}

div.detailView {
  position: relative;
  float: right;
  background-color: var(--background-colour);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
  height: 100%;
}

span.detailContainer.hidden {
  width: 0px;
}

div.collapsibleContainer {
  position: relative;
  width: 250px;
  /* transition: flex-grow 0.5s; */
}

div.collapsibleContent {
  position: relative;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--highlight-colour) #00000000;
  /* transition: height 0.5s; */
}

div.collapsibleContent::-webkit-scrollbar {
  display: none;
}

div.collapsibleContainer.open.fixed {
  flex-grow: 1;
}

div.collapsibleContainer.closed > div.collapsibleContent {
  height: 0px;
}

div.collapsibleLabel {
  position: relative;
  height: 20px;
  background-color: var(--highlight-colour);
  cursor: pointer;
}

span.collapsibleArrow {
  position: relative;
  display: inline-block;
  left: 5px;
  top: -2px;
  width: 7px;
  height: 7px;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid var(--text-colour);
  border-right: 1px solid var(--text-colour);
}

div.collapsibleContainer.open span.collapsibleArrow {
  transform: rotate(45deg);
}

div.collapsibleContainer.closed span.collapsibleArrow {
  transform: rotate(-45deg);
}

span.collapsibleTitle {
  position: relative;
  font-size: 14px;
  left: 10px;
  color: var(--text-colour);
  font-weight: bold;
  user-select: none;
}

div.collapsibleResizeBar {
  cursor: ns-resize;
  height: 5px;
}