span.appIcon {
  background-color: #2f2f2f;
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
}

span.appIcon > img.appIconImg {
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    border: none;
}
