button.controlButton {
  display: inline-block;
  position: relative;
  background-color: #00000000;
  border: none;
  width: 50px;
  height: 50px;
  padding: 0;
}

button.controlButton:hover {
  cursor: pointer;
}

button.controlButton:active {
  border: none;
  outline: none;
}

/* GEAR BUTTON */

span.gearSpindle {
  position: absolute;
  display: inline-block;
  background-color: #00000000;
  border-color: var(--highlight-colour);
  border-style: solid;
  width: 12px;
  height: 12px;
  border-width: 8px;
  border-radius: 16px;
  transition: border-color 0.2s;
  left: 11px;
  top: 11px;
}

span.gearSpindle > span {
  position: absolute;
  background-color: var(--highlight-colour);
  width: 8px;
  height: 8px;
  border-radius: 3px;
  transition: background-color 0.2s;
}

button.controlButton.gear:hover > span.gearSpindle {
  animation: rotate 5s linear infinite;
  border-color: var(--accent-colour);
}

button.controlButton.gear:hover > span.gearSpindle > span {
  background-color: var(--accent-colour);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* NEW FILE BUTTONS */

button.controlButton > span.new:nth-child(1) {
  left: 10px;
  top: 8px;
  width: 19px;
  height: 2px;
}

button.controlButton > span.new:nth-child(2) {
  left: 10px;
  top: 8px;
  width: 2px;
  height: 32px;
}

button.controlButton > span.new:nth-child(3) {
  left: 10px;
  top: 40px;
  width: 29px;
  height: 2px;
}

button.controlButton > span.new:nth-child(4) {
  left: 37px;
  top: 18px;
  width: 2px;
  height: 22px;
}

button.controlButton > span.new:nth-child(5) {
  left: 27px;
  top: 8px;
  width: 2px;
  height: 12px;
}

button.controlButton > span.new:nth-child(6) {
  left: 29px;
  top: 18px;
  width: 8px;
  height: 2px;
}

button.controlButton > span.new:nth-child(7) {
  left: 32px;
  top: 6.5px;
  width: 2px;
  height: 14px;
  transform: rotate(-45deg);
}

/* CREEPER BUTTON */

button.controlButton.creeper > span {
  position: absolute;
  display: inline-block;
  background-color: var(--highlight-colour);
  transition: background-color 0.2s;
  border: none;
}

button.controlButton.creeper:hover > span {
  background-color: var(--accent-colour);
}

button.controlButton.creeper > span.creeper:nth-child(8) {
  left: 16px;
  top: 21px;
  width: 6px;
  height: 6px;
}

button.controlButton.creeper > span.creeper:nth-child(9) {
  left: 28px;
  top: 21px;
  width: 6px;
  height: 6px;
}

button.controlButton.creeper > span.creeper:nth-child(10) {
  left: 22px;
  top: 27px;
  width: 6px;
  height: 9px;
}

button.controlButton.creeper > span.creeper:nth-child(11) {
  left: 19px;
  top: 30px;
  width: 3px;
  height: 9px;
}

button.controlButton.creeper > span.creeper:nth-child(12) {
  left: 28px;
  top: 30px;
  width: 3px;
  height: 9px;
}

/* ANVIL BUTTON */

button.controlButton.anvil > span {
  position: absolute;
  display: inline-block;
  background-color: var(--highlight-colour);
  transition: background-color 0.2s;
  border: none;
}

button.controlButton.anvil:hover > span {
  background-color: var(--accent-colour);
}

button.controlButton.anvil > span.anvil:nth-child(8) {
  left: 16px;
  top: 22px;
  width:16px;
  height: 7px;
}

button.controlButton.anvil > span.anvil:nth-child(9) {
  left: 20px;
  top: 29px;
  width: 8px;
  height: 3px;
}

button.controlButton.anvil > span.anvil:nth-child(10) {
  left: 19px;
  top: 32px;
  width: 10px;
  height: 1px;
}

button.controlButton.anvil > span.anvil:nth-child(11) {
  left: 17px;
  top: 33px;
  width: 14px;
  height: 4px;
}

/* TRASH BUTTON */

button.controlButton.trash > span {
  position: absolute;
  display: inline-block;
  background-color: var(--highlight-colour);
  transition: background-color 0.2s, border-color 0.2s;
  border: 1px var(--highlight-colour) solid;
  border-radius: 5px;
}

button.controlButton.trash:hover > span {
  background-color: var(--negative-accent-colour);
  border-color: var(--negative-accent-colour);
}

button.controlButton.trash > span:nth-child(1) {
  width: 8px;
  height: 12px;
  left: 20px;
  top: 6px;
}

button.controlButton.trash > span:nth-child(2) {
  width: 32px;
  height: 4px;
  left: 8px;
  top: 10px;
}

button.controlButton.trash > span:nth-child(3) {
  width: 24px;
  height: 32px;
  left: 12px;
  top: 10px;
}

/* EXIT BUTTON */

button.controlButton.exit > span {
  position: absolute;
  display: inline-block;
  background-color: var(--highlight-colour);
  transition: background-color 0.2s, border-color 0.2s;
  border: 0px solid;
  border-radius: 5px;
}

button.controlButton.exit:hover > span {
  background-color: var(--negative-accent-colour);
}

button.controlButton.exit > span:nth-child(1) {
  width: 40px;
  height: 6px;
  left: 5px;
  top: 22px;
  transform: rotate(45deg);
}

button.controlButton.exit > span:nth-child(2) {
  width: 40px;
  height: 6px;
  left: 5px;
  top: 22px;
  transform: rotate(-45deg);
}