span.viewport {
  position: relative;
  display: inline-block;
  top: 0px;
  right: 0px;
  height: 100%;
  min-width: calc(100% - 250px);
  max-width: calc(100% - 50px);
  flex-grow: 1;
}

canvas#renderTarget {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  z-index: 1;
}
